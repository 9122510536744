@white: #ffffff;
@black: #000000;

/** ---- Fonts family & sizes ---- */
@font-family-main: "Inter", Helvetica, Arial, sans-serif;

@font-main-size: 14px;
@font-main-line-height: 19px;
@font-main-weight: 400;

@font-heading-size: 16px;
@font-heading-line-height: 18px;
@font-heading-color: #1a1f36;


/** Visual state - baseline/normal */
@font-base-color: #3c4357;
@background-base: #FCFCFC;
@border-background-base: #F6F6F6;


/** Visual state - attention/warning */
@font-attention-color: #5d3c00;
@background-attention: #fffbe5;


/** Visual state - error */
@font-error-color: #ac2f33;
@background-error: #fee2dd;


/** Visual state - success */
@font-success-color: #0e6245;
@background-success: #f0ffed;


/** ---- Page background ---- */
@page-background: @white;


/** ---- Foreground colours ---- */
@foreground-primary: #427762;
@foreground-secondary: #697386; // Lighter version of the foreground-primary color
@border-foreground-primary: #3147C4; // CTA button hover for example


/** ---- Borders ---- */
@border-radius: 3px;
//@border-color-1: #c8d2df;
//@border-color-1: #efefef;
//@border-color-1: #d3d8df;
@border-color-1: #e4e7ec;
@border-1: 1px solid @border-color-1;

/** ---- Media sizes ---- */
@screen-xs: 480px;
@screen-sm: 768px;
@screen-md: 992px;
@screen-lg: 1050px;


/** ---- Spreadsheet styling below ---- */

/** Spreadsheet colours - header cell and columns*/
@sheet-header-cell-data-linked-background-color: rgba(149, 202, 181, 0.3);

/** Spreadsheet colours - main body cells */
@sheet-cell-font-size: 12px;
@sheet-cell-font-family: @font-family-main;
@sheet-cell-font-color: #505f66;
@sheet-cell-lifecycle-draft-background-color: '#FBF7E2';
@sheet-cell-lifecycle-server-updated-background-color: '#37cb95';

/** Spreadsheet colours - cell precedents and dependents */
@sheet-cell-precedent-level-1-background-color: rgba(141, 156, 168, 1);
@sheet-cell-precedent-level-1-foreground-color: #ffffff;
@sheet-cell-precedent-level-2-background-color: rgba(141, 156, 168, 0.8);
@sheet-cell-precedent-level-2-foreground-color: #ffffff;
@sheet-cell-precedent-level-3-background-color: rgba(141, 156, 168, 0.6);
@sheet-cell-precedent-level-3-foreground-color: #4d575e;
@sheet-cell-precedent-level-4-background-color: rgba(141, 156, 168, 0.4);
@sheet-cell-precedent-level-4-foreground-color: #4d575e;
@sheet-cell-precedent-level-5-background-color: rgba(141, 156, 168, 0.2);
@sheet-cell-precedent-level-5-foreground-color: #4d575e;

@sheet-cell-dependent-level-1-background-color: rgba(62, 160, 235, 1);
@sheet-cell-dependent-level-1-foreground-color: #ffffff;
@sheet-cell-dependent-level-2-background-color: rgba(62, 160, 235, 0.8);
@sheet-cell-dependent-level-2-foreground-color: #ffffff;
@sheet-cell-dependent-level-3-background-color: rgba(62, 160, 235, 0.6);
@sheet-cell-dependent-level-3-foreground-color: #2e5777;
@sheet-cell-dependent-level-4-background-color: rgba(62, 160, 235, 0.4);
@sheet-cell-dependent-level-4-foreground-color: #2e5777;
@sheet-cell-dependent-level-5-background-color: rgba(62, 160, 235, 0.2);
@sheet-cell-dependent-level-5-foreground-color: #2e5777;

/** Spreadsheet colours - comparison-specific colours **/
@sheet-cell-comparison-positive-background-color: #B1ECB5;
@sheet-cell-comparison-negative-background-color: #FFB7A5;
@sheet-cell-comparison-neutral-background-color: #BFCCD6;
@sheet-header-cell-comparison-baseEdition-background-color: #91ACE5;
@sheet-header-cell-comparison-baseEdition-foreground-color: #10161A;
@sheet-header-cell-comparison-targetEdition-background-color: #B3CFFF;
@sheet-header-cell-comparison-targetEdition-foreground-color: #10161A;
