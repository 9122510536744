//@import "inter";
//@import "icomoon";
@import "variables";

body {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: @font-family-main;
  font-size: @font-main-size;
  color: @font-base-color;
  line-height: @font-main-line-height;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
  overscroll-behavior-x: none; // Disable Chrome two finger back/forward

  background: @page-background;
}

html {
  height: 100%;
}

a {
  color: @font-base-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: @font-base-color;
    text-decoration: none;
  }
}

h1, h2, h3, h4 {
  color: @font-heading-color;
}

button {
  font-family: @font-family-main;
  font-weight: 500;
}

h3 {
  font-size: @font-heading-size;
  line-height: @font-heading-line-height;
}

//Remove outline around form elements
input:focus, textarea:focus, button:focus, select:focus {
  outline: none;
}

//.ctaButton {
//  background: @foreground-primary !important;
//  box-shadow: none !important;
//  color: @white !important;
//}

//.whiteFont {
//  color: @white !important;
//}


/* Shopify icon for spreadsheet context menus */
.icon-shopify-context-menu:before {
  font-family: 'icomoon';
  content: "\e900";
  color: @font-base-color;
  font-size: 17px;
  margin-left: 3px;
}

//Category icon for spreadsheet context menus
.icon-category-context-menu:before {
  font-family: 'icomoon';
  content: "\e905";
  color: @font-base-color;
  font-size: 15px;
}

// History icon for context menus
.icon-history-context-menu:before {
  font-family: 'icomoon';
  content: "\e90c";
  color: @font-base-color;
  font-size: 20px;
}
